import React, { forwardRef, useRef } from 'react';

import Image from '../../generic/Image';
import RichText from '../../generic/RichText';
import Section from '../../generic/Section';
import { mergeClassNames } from '../../../utils';
import styles from './index.module.scss';
import { useInViewport } from '../../../utils/useIntersectionObserver';

/**
 * @param {{feature: import('types').ContentfulProductFeature, id?: string, children?: React.ReactNode, eagerImageLoad?: boolean}} props
 * @returns JSX.Element
 */
export default function ProductFeature({ feature, id, children = undefined, eagerImageLoad = false }) {
  let intersectionRef = useRef(null);
  let inView = useInViewport(intersectionRef);

  if (!feature) return null;
  if (!feature.display) feature.display = 'Header';
  if (!feature.textPosition) feature.textPosition = 'Left';

  return (
    <ProductFeatureWrapper
      className={mergeClassNames(styles[feature.display], styles[feature.textPosition])}
      ref={intersectionRef}
      innerClassName={styles.inner}
      as={feature.display === 'Hero' ? 'header' : undefined}
      id={id}
    >
      {feature.images && (
        <Images images={feature.images} className={styles.images} inView={inView} eager={eagerImageLoad} />
      )}
      {children}
      <DeviceText feature={feature} className={styles.text} inView={inView} />
    </ProductFeatureWrapper>
  );
}

let ProductFeatureWrapper = forwardRef(
  /**
   * @param {object} props
   * @param {React.ReactNode} props.children
   * @param {keyof JSX.IntrinsicElements} [props.as]
   * @param {string} [props.id]
   * @param {string} [props.className]
   * @param {string} [props.innerClassName]
   * @param {React.ForwardedRef<any>} [ref]
   * @returns JSX.Element
   */
  function ProductFeatureWrapper({ children, as = undefined, id = '', className = '', innerClassName = '' }, ref) {
    return (
      <Section
        id={id}
        as={as}
        ref={ref}
        size="max"
        className={mergeClassNames(styles.section, className)}
        innerClassName={mergeClassNames(styles.sectionInner, innerClassName)}
      >
        {children}
      </Section>
    );
  }
);

/**
 * @param {{feature: import('types').ContentfulProductFeature, className: string, inView: boolean}} props
 * @returns JSX.Element
 */
function DeviceText({ feature, inView, className = '' }) {
  const { display, heading, description } = feature;
  return (
    <div className={mergeClassNames(inView ? styles.inView : null, className)}>
      {display === 'Hero' ? <h2>{heading}</h2> : <h3>{heading}</h3>}
      <RichText>{description}</RichText>
    </div>
  );
}

function Images({ images, inView, eager = false, className = '' }) {
  let [image] = images || [];
  if (!image) return null;
  return (
    <div className={mergeClassNames(inView ? styles.inView : null, className)}>
      <Image
        key={image.id}
        className={styles.image}
        image={image.image?.gatsbyImageData}
        alt={image.image?.description}
        eager={eager}
        shape={'roundedCorners'}
      />
    </div>
  );
}
