import styled, { css } from 'styled-components';
import { universalMargin, mediaQueries } from 'styles';

type SupportedSizes = 'small' | 'medium' | 'large';
const mapSupportedSizes: Record<SupportedSizes, number> = { small: 4, medium: 8, large: 16 };

type BoxProps = {
  padding?: SupportedSizes; //deprecated?
  paddingY?: SupportedSizes; //deprecated?
  paddingX?: SupportedSizes; //deprecated?
  mobilePaddingY?: SupportedSizes;
  tabletPaddingY?: SupportedSizes;
  desktopPaddingY?: SupportedSizes;
  backgroundColor?: string;
};

export const Box = styled.div<BoxProps>`
  padding: ${props => (props.paddingY ? universalMargin * mapSupportedSizes[props.paddingY] : 0)}px
    ${props => (props.paddingX ? universalMargin * mapSupportedSizes[props.paddingX] : 0)}px;

  ${props => props.padding && `padding: ${universalMargin * mapSupportedSizes[props.padding]}px`}

  ${props =>
    props.mobilePaddingY &&
    css`
      padding-top: ${universalMargin * mapSupportedSizes[props.mobilePaddingY]}px;
      padding-bottom: ${universalMargin * mapSupportedSizes[props.mobilePaddingY]}px;
    `};

  ${props =>
    props.tabletPaddingY &&
    mediaQueries.small(css`
      padding-top: ${universalMargin * mapSupportedSizes[props.tabletPaddingY]}px;
      padding-bottom: ${universalMargin * mapSupportedSizes[props.tabletPaddingY]}px;
    `)}

  ${props =>
    props.desktopPaddingY &&
    mediaQueries.medium(css`
      padding-top: ${universalMargin * mapSupportedSizes[props.desktopPaddingY]}px;
      padding-bottom: ${universalMargin * mapSupportedSizes[props.desktopPaddingY]}px;
    `)}
  ${props =>
    props.backgroundColor &&
    `
    background: ${props.backgroundColor};
    `}
`;
