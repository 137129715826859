import Head from 'components/Head';
import { ProductAvailability } from 'constants/contentful';
import ProductFeature from 'components/Products/ProductFeature';
import ProductsToc from 'components/Products/Toc';
import PwpNavGrid from 'components/PwpNavGrid';
import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { includeFutureProducts } from 'utils';
import { Box } from 'components/generic/Box';
import { ProductCategory as ProductCategoryType } from 'context/Quote/typedefs';
import styled, { css } from 'styled-components';
import { universalMargin, white } from 'styles';
import { isBuildTargetWebex } from 'white-label';

const sectionNavTitles = [
  { id: 'overview', title: 'Overview' },
  { id: 'features', title: 'Features' },
  { id: 'products', title: 'Products' },
];

type ProductCategoryContext = { id: string };
type ProductCategoryData = { productCategory: ProductCategoryType };

export default function ProductCategory(props: PageProps<ProductCategoryData, ProductCategoryContext>) {
  const pageData = props.data.productCategory;
  const location = props.location;
  const sections = pageData.sections?.filter(feature => {
    if (feature.product) {
      return includeFutureProducts || feature.product.availability !== ProductAvailability.Future;
    }
    return true;
  });

  return (
    <ProductCategoryWrapper isWebex={isBuildTargetWebex()}>
      <ProductsToc
        sections={sectionNavTitles.filter(filterFeatures(sections))}
        categoryLink={location.pathname}
        categoryHeading={pageData.heading} //? add more nesting when pageData.heading (nested project category)
      />
      <Head
        location={location}
        title={pageData.heading}
        description={pageData.description?.description}
        image={pageData.images?.[0].image?.file?.url}
      />

      {Boolean(sections?.length) &&
        sections.map((feature, index) => (
          <ProductFeature
            key={feature.id}
            feature={feature}
            id={index === 0 ? sectionNavTitles[1].id : undefined}
            eagerImageLoad={index === 0}
          />
        ))}

      <Box paddingY="large">
        <PwpNavGrid id={'products'} products={pageData.products} productGridTitle={pageData?.productGridTitle} />
      </Box>
    </ProductCategoryWrapper>
  );
}

const ProductCategoryWrapper = styled.div<{ isWebex: boolean }>`
  ${props =>
    props.isWebex &&
    css`
      background-color: black !important;
      color: ${white} !important;
      .hero {
        padding-top: ${universalMargin * 18}px;
      }
    `}
`;

/**
 * Only display 'features' if we got that section
 */
const filterFeatures = (features: ProductCategoryType['sections']) => (item: { id: string; title: string }) => {
  switch (item.id) {
    case 'features': {
      return Boolean(features && features.length);
    }
    default:
      return true;
  }
};

// TODO: this query feels excessive. Can we clean this up?
export const query = graphql`
  query ($id: String!) {
    productCategory: contentfulProductCategory(id: { eq: $id }) {
      ...ProductCategory
      ...ProductCategoryChildren
      ...ProductCategoryParent
    }
  }
`;

export const productCategoryFragments = graphql`
  fragment ProductCategory on ContentfulProductCategory {
    ...ProductCategoryBase
    sections {
      ...ProductFeature
    }
    products {
      ...SimpleProduct
      ...ProductCategoryBase
    }
  }

  fragment ProductCategoryChildren on ContentfulProductCategory {
    products {
      __typename
      ... on ContentfulProduct {
        categories: product_category {
          id
          slug
          ...ProductCategoryParent
        }
      }
      ...ProductCategory
      ...ProductCategoryParent
    }
  }

  fragment ProductCategoryParent on ContentfulProductCategory {
    parents: product_category {
      id
      slug
      parents: product_category {
        id
        slug
      }
    }
  }

  fragment ProductCategoryBase on ContentfulProductCategory {
    __typename
    id
    contentful_id
    heading
    shortHeading
    slug
    description {
      description
    }
    images: thumbnails {
      ...PwpGridImage
    }
    hideProductComparison
    gridSize
    gridLayout
    highlight
    productGridTitle
  }

  fragment GridProductCategory on ContentfulProductCategory {
    ...ProductCategoryBase
    parents: product_category {
      id
      slug
    }
  }
`;
