import { useEffect } from 'react';

export function useThrottledOnScroll(callback: (event: Event) => void, wait = 100) {
  useEffect(() => {
    const throttledCb = throttle(callback as (...args: Array<unknown>) => void, wait);
    window.addEventListener('resize', throttledCb);
    return () => window.removeEventListener('resize', throttledCb);
  }, [callback, wait]);
}

/**
 * @example
 * setInterval(
 *   throttle(() => console.log('call every 100ms, only triggered every 4000ms'), 4000),
 *   100
 * );
 */
export function throttle(callback: (...args: Array<unknown>) => void, wait = 500) {
  let lastTime = new Date(0);
  return (...args: Array<unknown>) => {
    const now = new Date();
    if (now.getTime() - lastTime.getTime() >= wait) {
      callback(...args);
      lastTime = now;
    }
  };
}
