import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isOnServer } from './isOnServer';
import { headerHeight } from 'styles';
import { useThrottledOnScroll } from './useOnScrollThrottled';

export const types = {
  default: `default`,
  lazyLoading: `lazyLoading`,
  atTopOfViewport: `atTopOfViewport`,
  atBottomOfViewport: `atBottomOfViewport`,
  viewport: `viewport`,
} as const;

const thresholds = { percentiles: Array.from(Array(100).keys(), i => i / 100), onceOnInit: 0 }; // get callbacks every 1/100 increase / decrease of intersection

type IntersectionSettings = {
  threshold: number | Array<number>;
  root?: Element | Document | null;
  rootMargin?: string;
};
export const presets = new Map<keyof typeof types, IntersectionSettings>()
  .set(types.default, { threshold: thresholds.percentiles })
  .set(types.atTopOfViewport, { threshold: thresholds.onceOnInit, rootMargin: '0px 0px -100%' })
  .set(types.atBottomOfViewport, { threshold: thresholds.onceOnInit, rootMargin: '-100% 0px 0px' })
  .set(types.lazyLoading, { threshold: thresholds.onceOnInit, rootMargin: '100%' }) // `isIntersecting` when elem closer than 100vw/vh from viewport, triggers once on way in and on way out
  .set(types.viewport, {
    threshold: thresholds.onceOnInit,
    rootMargin: `-${headerHeight}px 0px 0px 0px`,
  }) as ReadonlyMap<keyof typeof types, IntersectionSettings>; // project specific, follows scss $header-heght

export function useIntersectionObserver(
  nodeRef: RefObject<Element>,
  callback: (entry: IntersectionObserverEntry) => void,
  settings: IntersectionSettings | undefined = presets.get(types.default)
) {
  let { root = undefined, rootMargin = undefined, threshold = thresholds.percentiles } = settings || {};
  const observer = useRef(
    !isOnServer
      ? new IntersectionObserver(([entry]) => callback(entry), {
          root,
          rootMargin,
          threshold,
        })
      : undefined
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    if (!currentObserver) return;
    currentObserver.disconnect();
    if (nodeRef.current) currentObserver.observe(nodeRef.current);
    return () => currentObserver.disconnect();
  }, [nodeRef, callback, settings]);
}

/* Predefined intersection observers below 👇 */
export function useAtTopOfViewport(nodeRef: RefObject<Element>, initState = false) {
  const [isIntersecting, setIsIntersecting] = useState(initState);
  let [headerHeightPercentage, setHeaderHeightPercentage] = useState(
    (headerHeight / ((!isOnServer && window?.innerHeight) || 0)) * 100
  );
  useThrottledOnScroll(() =>
    setHeaderHeightPercentage(Math.min(100, (headerHeight / ((!isOnServer && window?.innerHeight) || 0)) * 100))
  );
  let settings = useMemo(
    () => ({
      threshold: thresholds.onceOnInit,
      rootMargin: `-${headerHeight}px 0px -${100 - headerHeightPercentage}%`,
    }),
    [headerHeightPercentage]
  );
  let callback = useCallback(({ isIntersecting }) => setIsIntersecting(isIntersecting), []);
  useIntersectionObserver(nodeRef, callback, settings);
  return isIntersecting;
}

export function useAtBottomOfViewport(nodeRef: RefObject<Element>, initState = false) {
  const [isIntersecting, setIsIntersecting] = useState(initState);
  let callback = useCallback(({ isIntersecting }) => setIsIntersecting(isIntersecting), []);
  useIntersectionObserver(nodeRef, callback, presets.get(types.atBottomOfViewport));
  return isIntersecting;
}

export function useCoversViewport(nodeRef: RefObject<Element>, initState = false) {
  const isIntersectingTop = useAtTopOfViewport(nodeRef, initState);
  const isIntersectingBottom = useAtBottomOfViewport(nodeRef, initState);
  return isIntersectingTop && isIntersectingBottom;
}

export function useInViewport(nodeRef: RefObject<Element>, initState = false) {
  const [isInView, setInView] = useState(initState);
  let callback = useCallback(({ isIntersecting }) => setInView(isIntersecting), []);
  useIntersectionObserver(nodeRef, callback, presets.get(types.viewport));
  return isInView;
}

export function useInCloseProximityToViewport(nodeRef: RefObject<Element>, initState = false) {
  const [isCloseToView, setCloseToView] = useState(initState);
  let callback = useCallback(({ isIntersecting }) => setCloseToView(isIntersecting), []);
  useIntersectionObserver(nodeRef, callback, presets.get(types.lazyLoading));
  return isCloseToView;
}
