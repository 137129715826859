import Breadcrumbs, {
  BreadcrumbGoToAnchor,
  BreadcrumbLink,
  LocationBreadcrumbs,
} from '../../SecondaryNavigation/Breadcrumbs';

import React from 'react';

export default function ProductsToc({ sections, categoryLink, categoryHeading }) {
  return (
    <Breadcrumbs>
      <LocationBreadcrumbs>
        <BreadcrumbLink to="/products">Devices</BreadcrumbLink>
        <BreadcrumbLink to={categoryLink}>{categoryHeading}</BreadcrumbLink>
      </LocationBreadcrumbs>
      {sections.map(({ id, title }) => (
        <BreadcrumbGoToAnchor key={id} id={id}>
          {title}
        </BreadcrumbGoToAnchor>
      ))}
    </Breadcrumbs>
  );
}
